<template>
    <div class="tip-box">
        <div class="tip-top">{{text}}<el-button @click="refreshData">{{buttonTxt}}</el-button></div>
        <div class="tip-list">
            <div class="tip-item" v-for="(tip,index) in tipData" :key="index" @click="handleText(tip)">{{tip}}</div>
        </div>
    </div>
</template>

<script>
  export default {
    name:"tips",
    props: {
      tipData: {type: Array, required: true}, //数据
    },
    data(){
      return {
          text: "您可能这样问：",
          buttonTxt: "换一批",
      }
    },
    methods: {
        refreshData() {
            window.parent.refreshData();
        },
        handleText(text) {
            window.parent.handleText(text);
        }
    }
  } 
</script>

<style scoped>
.tip-box {
    padding: 0 15px;
}
.tip-top {
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 5px 0;
}
.tip-top .el-button {
    border: none;
    background-color: transparent;
    padding: 0 0 0 18px;
    background-image: url(../../assets/icon/icon_refresh2.png);
    background-repeat: no-repeat;
    margin: 0 5px;
}
.tip-top .el-button:focus {
    color: #c90e17;
}

.tip-top .el-button:hover {
    background-color: transparent;
    color:#c90e17;
}
.tip-list {
    display: flex;
    align-items: center;
    height: 40px;
}
.tip-item {
    background-color: #e6e9f1;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 30px;
    margin: 5px 10px 10px 0;
    cursor: pointer;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.tip-item:hover {
    background-color: #c90e17;
    color: #fff;
}
</style>